import { t } from 'i18next';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useLazyGetUserQuery } from '../../../store/services/userService';
import { useAppSelector } from '../../../store/hooks';
import { Role } from '../../../types/role';
import { PATHS } from '../../../routes';
import {
  useLazyGetFreeConsultationLinkQuery,
} from '../../../store/services/hiLinkService';
import {
  IChatMessagesQuery,
  useLazyGetChatMessagesQuery,
} from '../services/chatService';
import {
  addChatRoom,
  clearActiveChatRoom,
  getMessagesById,
  IChatRoom,
  ISendChatMessage,
  readMessages,
  setConsultationInitialized,
  setFreeConsultation,
  setLink,
} from '../slices/chatSlice';
import SendMessageForm from './SendMessageForm';
import ImageCircle from '../../../components/ImageCircle';
import {
  getUserRoleAbbrv,
  useLazyGetTutorByIdQuery,
} from '../../../store/services/tutorService';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../../styles/base/vars.scss';
import {
  BsCheck,
  BsCheckAll,
  BsDownload,
  BsFillFileEarmarkFill,
} from 'react-icons/bs';
import MediaQuery from 'react-responsive';
import { AiOutlineLeft } from 'react-icons/ai';

interface Props {
  data: IChatRoom | null;
}

const fileUrl = 'api/v1/chat/download';
const url = `${process.env.REACT_APP_SCHEMA}://${process.env.REACT_APP_CHAT_FILE_DOWNLOAD_HOST}/${fileUrl}`;

const SingleConversation = (props: Props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  const connectionRef = useRef<any>(null);
  const userActive = useAppSelector((state) => state.auth.user);
  const [getTutorById] = useLazyGetTutorByIdQuery();

  const chat = useAppSelector((state) => state.chat);

  const [page, setPage] = useState<number>(0);

  const [getUserById, { data: user2Data }] = useLazyGetUserQuery();
  const [getUserById2, { data: user2Data2 }] = useLazyGetUserQuery();

  const [freeConsultationClicked, setFreeConsultationClicked] = useState<boolean>(false);
  const [freeCallExpired, setFreeCallExpired] = useState<boolean>(false);
  const [freeCallCancelled, setFreeCallCancelled] = useState<boolean | null>(null);
  const [freeCallCancel, setFreeCallCancel] = useState<boolean>(false);

  const [myStream, setMyStream] = useState<any>(null);
  const [guestStream, setGuestStream] = useState<any>(null);

  const [getChatMessages, { data: chatMessages }] = useLazyGetChatMessagesQuery();

  const [getFreeConsultationLink, {
    data: freeConsultationLink,
    isSuccess: freeConsultationIsSuccess,
  }] = useLazyGetFreeConsultationLinkQuery();

  const userToken = useAppSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const scrollToBottomSmooth = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  let lastMessageUserId: string = '';

  useEffect(() => {
    scrollToBottomSmooth();
  }, []);

  useEffect(() => {
    if (props.data && chatRef.current && props.data.messages.length <= chat.rpp) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  });

  useEffect(() => {
    if (chatMessages && props.data) {
      dispatch(
        getMessagesById({
          userId: props.data.user?.userId + '',
          tutorId: props.data?.tutor?.userId + '',
          messages: chatMessages,
        }),
      );
    }
  }, [chatMessages]);

  useEffect(() => {
    if (props.data) {
      dispatch(
        readMessages({
          userId: props.data.user?.userId + '',
          tutorId: props.data?.tutor?.userId + '',
        }),
      );
    }
  }, [props.data]);

  useEffect(() => {
    if (userActive && props.data && page > 0) {
      const getMessagesObject: IChatMessagesQuery = {
        userId: (userActive.id == props.data?.user?.userId ? props.data.tutor?.userId : props.data?.user?.userId) || '',
        page: page,
        rpp: chat.rpp,
      };
      getChatMessages(getMessagesObject);
    }
  }, [page]);

  useEffect(() => {
    if (user2Data && user2Data2 && freeCallCancel) {
      let messageText = 'userInsert={username} stringTranslate={NOTIFICATIONS.CHAT_HAS_MISSED_CALL}';
      messageText = messageText.replace(/stringTranslate=\{(.*?)\}/g, function(match: any, token: any) {
        return t(token);
      });
      messageText = messageText.replace(/userInsert=\{(.*?)\}/g, function(match: any, token: any) {
        return userActive?.id !== user2Data.id
          ? user2Data.firstName + ' ' + user2Data?.lastName
          : user2Data2.firstName + ' ' + user2Data2?.lastName;
      });

      const message = {
        userId: user2Data.id + '',
        tutorId: user2Data2.id + '',
        message: {
          message: messageText,
          createdAt: new Date().toISOString(),
          isRead: true,
          messageId: '',
          isFile: false,
          messageNew: true,
          messageMissedCall: true,
        },
        senderId: (userActive?.id || chat.buffer?.senderId) == user2Data.id ? user2Data2.id : user2Data.id,
      };

      const chatRoom: IChatRoom = {
        user: {
          userId: user2Data?.id + '',
          userImage: 'teorem.co:3000/profile/images/profilePictureDefault.jpg',
          userNickname: user2Data?.firstName + ' ' + user2Data?.lastName,
        },
        tutor: {
          userId: user2Data2?.id + '',
          userImage: user2Data2?.profileImage,
          userNickname: user2Data2.firstName + ' ' + user2Data2?.lastName,
        },
        messages: [message],
        unreadMessageCount: 0,
      };

      dispatch(addChatRoom(chatRoom));
    }
  }, [user2Data, user2Data2, freeCallCancel]);

  const handleChatInit = (freeConsultation: boolean = false) => {
    dispatch(setConsultationInitialized(false));
    setFreeConsultationClicked(false);
    dispatch(setFreeConsultation(freeConsultation));
    dispatch(setLink(null));
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const hideLoadMore = () => {
    let returnValue: boolean = false;
    if (props.data && chatMessages) {
      const totalPages = Math.ceil(chatMessages.length / chat.rpp);

      if (totalPages < 1) returnValue = true;
    }

    return returnValue;
  };

  //scroll to bottom alerter
  const handleScroll = (e: HTMLDivElement) => {
    const scrollPosition = 0;

    if (props.data && !hideLoadMore() && e.scrollTop === scrollPosition && props.data?.messages.length > 0) {
      handleLoadMore();
    }
    // if (innerHeight === scrollPosition) {
    //     //action to do on scroll to bottom
    //
    // }
  };

  const onFreeConsultation = () => {
    if (freeConsultationClicked == false) {
      dispatch(setFreeConsultation(true));
      //getFreeConsultationLink(props.data?.tutor?.userId + '');
      setFreeConsultationClicked(true);
      dispatch(setConsultationInitialized(true));
      setFreeCallExpired(false);
    }
  };

  const debouncedScrollHandler = debounce((e) => handleScroll(e), 500);

  let lastDate = '';
  let lastMessageTime: Date;
  let groupTimestamp = '';

  const [tutorSlug, setTutorSlug] = useState('');

  async function getTutorSlug() {
    if (props.data && props.data.tutor) {
      const tutorData = await getTutorById(props.data.tutor.userId).unwrap();
      setTutorSlug(tutorData.slug);
    }
  }

  useEffect(() => {
    getTutorSlug();
  }, [props?.data?.tutor?.userId]);

  function formatTime(hours: number, minutes: number) {
    // Pad single-digit hours and minutes with leading zeros
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Combine the formatted hours and minutes with a colon separator
    return `${formattedHours}:${formattedMinutes}`;
  }

  const downloadFile = (documentId: string | undefined) => {
    fetch(`${url}/${documentId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/octet-stream',
      },
    }).then((response) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const fileName = contentDisposition?.split('=')[1];

      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName + '';
        a.click();
      });
    });
  };

  const cacheBuster = new Date();

  function removeActiveChatRoom() {
    dispatch(clearActiveChatRoom());
  }

  return (
    <div className='content'>
      <div className='content__header content__header--chat'>
        <div className='flex flex--center'>
          <MediaQuery maxWidth={765}>
            <AiOutlineLeft className='signup-icon' color='grey'
                           onClick={removeActiveChatRoom} />
          </MediaQuery>
          {props.data && userActive?.Role.abrv != Role.Tutor && (
            <Link
              className='chat-single-conversation-link flex flex--center'
              to={`${PATHS.SEARCH_TUTORS_TUTOR_PROFILE.replace(':tutorSlug', `${tutorSlug}`)}`}
            >
              {props.data &&
                (props.data.tutor?.userImage !== undefined ? (
                  <img className='chat__conversation__avatar'
                       src={props.data.tutor?.userImage} alt='user avatar' />
                ) : (
                  <ImageCircle
                    initials={
                      getUserRoleAbbrv() === 'tutor'
                        ? `${props.data.user?.userNickname.split(' ')[0].charAt(0)}${props.data.user?.userNickname
                          .split(' ')[1]
                          .charAt(0)}`
                        : `${props.data.tutor?.userNickname.split(' ')[0].charAt(0)}${props.data.tutor?.userNickname
                          .split(' ')[1]
                          .charAt(0)}`
                    }
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    fontSize={20}
                  />
                ))}

              <div className='ml-3 type--wgt--bold'>
                {props.data
                  ? userActive?.id != props.data.tutor?.userId
                    ? props.data.tutor?.userNickname
                    : props.data.user?.userNickname
                  : 'Odaberite osobu za razgovor'}
              </div>
            </Link>
          )}

          {props.data && userActive?.Role.abrv == Role.Tutor && (
            <div>
              {props.data.user?.userImage !== undefined ? (
                <img
                  className='chat__conversation__avatar'
                  src={`${props.data.user?.userImage}&v=${cacheBuster}`}
                  alt={'profile avatar'}
                />
              ) : (
                <ImageCircle
                  initials={`${userActive?.firstName.charAt(0)}${userActive?.lastName.charAt(0)}`}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  fontSize={20}
                />
              )}
            </div>
          )}

          {/*    // <ImageCircle*/}
          {/*    //     initials={`${props.data.user?.userNickname.charAt(0)}${props.data.user?.userNickname?.split(' ')[1]?.charAt(0)}`}*/}
          {/*    // />*/}
          {/*)}*/}

          {props.data && userActive?.Role.abrv == Role.Tutor && (
            <div className='ml-3 type--wgt--bold'>
              {props.data
                ? userActive?.id != props.data.tutor?.userId
                  ? props.data.tutor?.userNickname
                  : props.data.user?.userNickname
                : 'Odaberite osobu za razgovor'}
            </div>
          )}
        </div>
      </div>

      <div ref={chatRef} onScroll={(e: any) => debouncedScrollHandler(e.target)}
           className='content__main'>
        {props.data && props.data.messages.length >= 20 && !hideLoadMore() && (
          <div>
            <i className={`icon--loader chat-load-more`}></i>
          </div>
        )}
        {props.data && props.data.messages.length == 0 && (
          <div className={`chat_message_init_new`}>
            <div className={`message-full-width flex flex--col flex--center`}>
              <div className='type--right w--80--max'>
                <div
                  className={`chat__message__item__center chat__message__item chat__message__item__init`}>
                  <i>{t('CHAT.PLACEHOLDER')}</i>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.data &&
          props.data.messages.length > 0 &&
          props.data.messages.map((message: ISendChatMessage, index: number) => {
            let img = false;
            const temDat = new Date(message.message.createdAt);

            if (lastMessageTime == undefined) {
              lastMessageTime = temDat;
            }

            if (groupTimestamp == '') {
              groupTimestamp = formatTime(temDat.getHours(), temDat.getMinutes());
            }

            const difference = temDat.getTime() - lastMessageTime.getTime();
            if (difference >= 30 * 60 * 1000) {
              groupTimestamp = formatTime(temDat.getHours(), temDat.getMinutes());
            }

            lastMessageTime = temDat;

            const messageTime = formatTime(temDat.getHours(), temDat.getMinutes());

            const tempDate = temDat.getDate() + '-' + temDat.getMonth() + '-' + temDat.getUTCFullYear() + ',' + groupTimestamp;

            let sameDate = true;

            if (lastDate != tempDate) {
              sameDate = false;

              lastDate = tempDate;
            }

            if (message.senderId !== lastMessageUserId) {
              img = true;
              lastMessageUserId = message.senderId + '';
            }

            if (!sameDate) {
              img = true;
            }

            if (props.data && index == props.data.messages.length - 1) {
              scrollToBottomSmooth();
            }

            if (message.message.messageMissedCall && userActive?.id == message.senderId) return <></>;

            let messageText = message.message.message || '';
            messageText = messageText.replace(/stringTranslate=\{(.*?)\}/g, function(match: any, token: any) {
              return t(token);
            });
            messageText = messageText.replace(/userInsert=\{(.*?)\}/g, function(match: any, token: any) {
              return message.senderId == message.tutorId ? `${props.data?.tutor?.userNickname}` : `${props.data?.user?.userNickname}`;
            });

            if (userActive && userActive.id == message.senderId)
              return (
                <>
                  <Tooltip
                    id='my-tooltip'
                    place={'top-start'}
                    positionStrategy={'absolute'}
                    float={true}
                    delayShow={1000}
                    style={{
                      backgroundColor: 'rgba(70,70,70, 0.9)',
                      color: 'white',
                      fontSize: 'smaller',
                    }}
                  />
                  {!sameDate && (
                    <div
                      className={`message-full-width flex flex--col flex--center`}>
                                            <span>
                                                {moment(message.message.createdAt).format(t('DATE_FORMAT'))}, {messageTime}
                                            </span>
                    </div>
                  )}
                  <div
                    key={index}
                    className={`chat__message chat__message--logged${img ? ' chat__message__margin-top' : ''}${
                      img ? '' : ' chat__message__margin-right'
                    }`}
                  >
                    {img &&
                      //TODO: ovdje
                      props.data &&
                      (message.senderId == props.data.tutor?.userId && props.data.tutor?.userImage !== undefined ? (
                        <img
                          className='chat__conversation__avatar chat__conversation__avatar--small'
                          src={`${props.data.tutor?.userImage}&v=${cacheBuster}`}
                          alt={'profile avatar'}
                        />
                      ) : (
                        <div>
                          {props.data.user?.userImage !== undefined ? (
                            <img
                              className='chat__conversation__avatar chat__conversation__avatar--small'
                              src={`${props.data.user?.userImage}&v=${cacheBuster}`}
                              alt={'profile avatar'}
                            />
                          ) : (
                            <ImageCircle
                              initials={`${userActive?.firstName.charAt(0)}${userActive?.lastName.charAt(0)}`}
                              style={{
                                width: 40,
                                height: 40,
                              }}
                              fontSize={20}
                            />
                          )}
                        </div>
                      ))}

                    <div key={`sub-${index}`}
                         className={`message-full-width flex flex--col flex--end`}>
                      <div key={`sub-sub-${index}`}
                           className='type--right w--80--max'>
                        <div
                          data-tooltip-id='my-tooltip'
                          data-tooltip-html={`Sent: ${messageTime} <br/>${
                            message.message.isFile || message.message.file ? `File name: ${message.message.message}` : ''
                          }`}
                          key={`sub-sub-sub-${index}`}
                          className={`d-inline-flex  chat__message__item chat__message__item__end chat__message__item--logged${
                            message.message.isFile ? ' chat-file-outline' : ''
                          }`}
                        >
                          {message.message.isFile || message.message.file ? (
                            <div className='file-message-container'>
                              <BsFillFileEarmarkFill
                                className='text-primary align-self-center' />
                              <div className='file-message-container'>
                                <div
                                  className='text-break text-black align-self-center ml-2'>
                                  {message.message.message.length > 30
                                    ? `${message.message.message.slice(0, 30)}...`
                                    : message.message.message}
                                </div>

                                <div
                                  role='button'
                                  className='d-inline-block h-auto shadow-sm align-self-center mx-2'
                                  onClick={() => downloadFile(message.message.messageId)}
                                >
                                  <BsDownload
                                    className='border-hover text-black' />
                                </div>

                                {message.message.isRead ? (
                                  <BsCheckAll
                                    className='align-self-end text-black' />
                                ) : (
                                  <BsCheck
                                    className='align-self-end text-black' />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className='message-container'>
                              <div className='chat-wrap'>
                                {message.message.message}
                              </div>
                              {message.message.isRead ? (
                                <BsCheckAll className='align-self-end' />
                              ) : (
                                <BsCheck className='align-self-end' />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            return (
              <>
                {!sameDate && (
                  <div
                    className={`message-full-width flex flex--col flex--center`}>
                                        <span>
                                            {moment(message.message.createdAt).format(t('DATE_FORMAT'))}, {messageTime}
                                        </span>
                  </div>
                )}
                <div
                  key={index}
                  className={`chat__message chat__message--other${img ? ' chat__message__margin-top' : ''}${
                    img ? '' : ' chat__message__margin-left'
                  }`}
                >
                  {img &&
                    props.data &&
                    (message.senderId == props.data.tutor?.userId && props.data.tutor?.userImage !== undefined ? (
                      <img
                        className='chat__conversation__avatar chat__conversation__avatar--small'
                        src={`${props.data.tutor?.userImage}&v=${cacheBuster}`}
                        alt={'profile avatar'}
                      />
                    ) : (
                      <div>
                        {message.senderId == props.data.tutor?.userId ? (
                          <ImageCircle
                            className='image-40'
                            fontSize={20}
                            initials={`${props.data.tutor?.userNickname
                              .split(' ')[0]
                              .charAt(0)}${props.data.tutor?.userNickname.split(' ')[1].charAt(0)}`}
                          />
                        ) : (
                          <div>
                            {props.data.user?.userImage ? (
                              <img
                                className='chat__conversation__avatar chat__conversation__avatar--small'
                                src={`${props.data.user?.userImage}&v=${cacheBuster}`}
                                alt={'profile avatar'}
                              />
                            ) : (
                              <ImageCircle
                                className='image-40'
                                fontSize={20}
                                initials={`${props.data.user?.userNickname
                                  .split(' ')[0]
                                  .charAt(0)}${props.data.user?.userNickname.split(' ')[1].charAt(0)}`}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}

                  <div key={`sub-${index}`}
                       className={`message-full-width flex flex--col`}>
                    <div key={`sub-sub-${index}`} className='w--80--max'>
                      <div
                        data-tooltip-id='my-tooltip'
                        data-tooltip-html={`Sent: ${messageTime} <br/>${
                          message.message.isFile || message.message.file ? `File name: ${message.message.message}` : ''
                        }`}
                        key={`sub-sub-sub-${index}`}
                        className={`chat__message__item chat__message__item--other${
                          message.message.isFile || message.message.file ? ' chat-file-outline' : ''
                        }`}
                      >
                        {message.message.isFile || message.message.file ? (
                          <div className='file-message-container'>
                            <BsFillFileEarmarkFill
                              className='text-primary align-self-center' />
                            <div className='file-container mx-2'>
                              {message.message.message.length > 30
                                ? `${message.message.message.slice(0, 30)}...`
                                : message.message.message}
                            </div>
                            <div
                              role='button'
                              className='d-inline-block h-auto shadow-sm'
                              onClick={() => downloadFile(message.message.messageId)}
                            >
                              <BsDownload
                                className='border-hover align-self-center text-black' />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={'chat-wrap'}>{message.message.message}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        <div style={{ marginTop: 80 }} ref={messagesEndRef} />
      </div>
      {props.data && <SendMessageForm data={props.data}
                                      scrollOnSend={scrollToBottomSmooth} />}
    </div>
  );
};

export default SingleConversation;
