import ISubject from '../../../../types/ISubject';

export const popularSubjects:ISubject[] = [
  {
    "id": "2da9dfdb-e9cc-479a-802d-fa2a9b906575",
    "abrv": "maths",
    "name": "Maths"
  },
  {
    "id": "0cfe5ab2-843d-44d3-bee6-718097044e15",
    "abrv": "english",
    "name": "English"
  },
  {
    "id": "0fedd928-bdca-47de-b8c7-3becb6520996",
    "abrv": "chemistry",
    "name": "Chemistry"
  },
  {
    "id": "31457765-8029-4952-8094-f7ed97c741a7",
    "abrv": "biology",
    "name": "Biology"
  },
  {
    "id": "18d0fe98-0582-4528-a369-cdad78072e2a",
    "abrv": "croatian",
    "name": "Croatian"
  },
  {
    "id": "8caedad0-55e5-4220-a7e7-f4aab4a3fbb8",
    "abrv": "physics",
    "name": "Physics",
  }
];

export const allSubjects:ISubject[] = [
  // {
  //   "id": "5fb0882c-351f-49f9-9be9-ab130966dfc5",
  //   "abrv": "polish",
  //   "name": "Polish"
  // },
  {
    "id": "052e35d6-8d15-4a95-be5b-ebb84529d916",
    "abrv": "spanish",
    "name": "Spanish"
  },
  // {
  //   "id": "51e058a2-b9c5-4cf0-ba52-76e65f5c4cfd",
  //   "abrv": "russian",
  //   "name": "Russian"
  // },
  // {
  //   "id": "5389adb8-ac5b-4d11-a5d2-e2188ef21fce",
  //   "abrv": "religion",
  //   "name": "Religion"
  // },
  {
    "id": "1e834b3c-b5e7-422d-8eae-01b9995848a1",
    "abrv": "italian",
    "name": "Italian"
  },
  // {
  //   "id": "2d792709-b688-4288-88e4-7fa6928e9be6",
  //   "abrv": "art",
  //   "name": "Art"
  // },
  {
    "id": "d2cd6016-6755-4803-9910-190c416d7951",
    "abrv": "classical-greek",
    "name": "Classical Greek"
  },
  // {
  //   "id": "a75e1de6-f20a-4fc5-8dd8-abf8ed5244f9",
  //   "abrv": "computing",
  //   "name": "Computing"
  // },
  // {
  //   "id": "f07d4d58-5ee3-433b-8a88-744aa57a4db3",
  //   "abrv": "design-and-technology",
  //   "name": "Design & Technology"
  // },
  // {
  //   "id": "41725b91-dfce-464f-a6d8-34d49a9f26f8",
  //   "abrv": "drama",
  //   "name": "Drama"
  // },
  {
    "id": "b57590ec-43b6-4db7-a283-8b9f516a5257",
    "abrv": "french",
    "name": "French"
  },
  // {
  //   "id": "9aa0d7fd-eb3d-4a77-9f9b-20de39737ed3",
  //   "abrv": "environmental-studies",
  //   "name": "Environmental Studies"
  // },
  {
    "id": "51699d47-dadc-43dd-b9c3-7aff3b8f3836",
    "abrv": "geography",
    "name": "Geography"
  },
  {
    "id": "30b85199-b105-4743-b6a5-6a5d91e3c240",
    "abrv": "government-and-politics",
    "name": "Government and Politics"
  },
  {
    "id": "b08a9a10-4928-4423-b805-6eba74c6d50b",
    "abrv": "history",
    "name": "History"
  },
  // {
  //   "id": "77e7a466-e6dd-4021-bca4-8b1218096ff4",
  //   "abrv": "science",
  //   "name": "Science"
  // },
  {
    "id": "029a08ea-9828-4a4c-aa10-61e9e6ed2378",
    "abrv": "german",
    "name": "German"
  },
  // {
  //   "id": "12e4faa1-705a-47a5-b3b4-7a02b5753205",
  //   "abrv": "economics",
  //   "name": "Economics"
  // },
  {
    "id": "b2e27fa4-a374-4959-9725-0be802a0c9fe",
    "abrv": "sociology",
    "name": "Sociology"
  },
  // {
  //   "id": "60a54517-e5b3-4309-be4c-92001ac17831",
  //   "abrv": "medicine",
  //   "name": "Medicine"
  // },
  // {
  //   "id": "50f53cc2-2209-4477-9ce2-397c697c69db",
  //   "abrv": "statistics",
  //   "name": "Statistics"
  // },
  {
    "id": "459e0de7-a132-4bbe-a458-ca28b0cc960c",
    "abrv": "it",
    "name": "IT"
  },
  // {
  //   "id": "3f74cfd7-a33d-45f3-afe5-27c798e9aa7d",
  //   "abrv": "law",
  //   "name": "Law"
  // },
  // {
  //   "id": "6bbb1ca4-dc86-4ffa-965c-71455720d871",
  //   "abrv": "architecture",
  //   "name": "Architecture"
  // },
  // {
  //   "id": "b5769e82-6cbb-4534-9f19-db628e0dd514",
  //   "abrv": "philosophy",
  //   "name": "Philosophy"
  // },
  // {
  //   "id": "e109b6cd-2a6a-4ec8-884e-141ef0805c22",
  //   "abrv": "politics",
  //   "name": "Politics"
  // },
  // {
  //   "id": "101637f9-7aae-4376-a9a1-3227ecfe4ac8",
  //   "abrv": "psychology",
  //   "name": "Psychology"
  // },
  // {
  //   "id": "4453024b-8415-4cfe-90b5-bfcbeb78de32",
  //   "abrv": "music",
  //   "name": "Music"
  // },
  // {
  //   "id": "17e00ac5-25b3-4124-a47f-126a4025a5a0",
  //   "abrv": "business-studies",
  //   "name": "Business Studies"
  // },
  // {
  //   "id": "9151e694-25a0-40ff-91f8-6bc2be22f7ce",
  //   "abrv": "chemical-engineering",
  //   "name": "Chemical Engineering"
  // },
  // {
  //   "id": "bec3b802-8691-434a-8618-740d277cc8c6",
  //   "abrv": "accounting",
  //   "name": "Accounting"
  // },
  {
    "id": "edc33bbf-6cc3-481f-a19e-e859bbaac1ea",
    "abrv": "latin",
    "name": "Latin"
  },
];

export const allActiveSubjects:ISubject[] = [
  {
    "id": "2da9dfdb-e9cc-479a-802d-fa2a9b906575",
    "abrv": "maths",
    "name": "Maths"
  },
  {
    "id": "0cfe5ab2-843d-44d3-bee6-718097044e15",
    "abrv": "english",
    "name": "English"
  },
  {
    "id": "0fedd928-bdca-47de-b8c7-3becb6520996",
    "abrv": "chemistry",
    "name": "Chemistry"
  },
  {
    "id": "31457765-8029-4952-8094-f7ed97c741a7",
    "abrv": "biology",
    "name": "Biology"
  },
  {
    "id": "18d0fe98-0582-4528-a369-cdad78072e2a",
    "abrv": "croatian",
    "name": "Croatian"
  },
  {
    "id": "8caedad0-55e5-4220-a7e7-f4aab4a3fbb8",
    "abrv": "physics",
    "name": "Physics",
  },
  {
    "id": "052e35d6-8d15-4a95-be5b-ebb84529d916",
    "abrv": "spanish",
    "name": "Spanish"
  },
  {
    "id": "1e834b3c-b5e7-422d-8eae-01b9995848a1",
    "abrv": "italian",
    "name": "Italian"
  },
  {
    "id": "d2cd6016-6755-4803-9910-190c416d7951",
    "abrv": "classical-greek",
    "name": "Classical Greek"
  },
  {
    "id": "b57590ec-43b6-4db7-a283-8b9f516a5257",
    "abrv": "french",
    "name": "French"
  },
  {
    "id": "51699d47-dadc-43dd-b9c3-7aff3b8f3836",
    "abrv": "geography",
    "name": "Geography"
  },
  {
    "id": "30b85199-b105-4743-b6a5-6a5d91e3c240",
    "abrv": "government-and-politics",
    "name": "Government and Politics"
  },
  {
    "id": "b08a9a10-4928-4423-b805-6eba74c6d50b",
    "abrv": "history",
    "name": "History"
  },
  {
    "id": "029a08ea-9828-4a4c-aa10-61e9e6ed2378",
    "abrv": "german",
    "name": "German"
  },
  {
    "id": "b2e27fa4-a374-4959-9725-0be802a0c9fe",
    "abrv": "sociology",
    "name": "Sociology"
  },
  {
    "id": "459e0de7-a132-4bbe-a458-ca28b0cc960c",
    "abrv": "it",
    "name": "IT"
  },
  {
    "id": "edc33bbf-6cc3-481f-a19e-e859bbaac1ea",
    "abrv": "latin",
    "name": "Latin"
  },
];
